"use strict";
$(function() {
  $.attachUA();
  $('#menu-toggle').toggleMenu('is-menu-on');
  $('a[href*="#"]').not('.no').smoothScroll(600);
  return this;
});

(function($) {
  $.createViewport = function(width) {
    var i, k, metalist, name, ref;
    metalist = document.getElementsByTagName('meta');
    for (i = k = 0, ref = metalist.length; 0 <= ref ? k < ref : k > ref; i = 0 <= ref ? ++k : --k) {
      name = metalist[i].getAttribute('name');
      if ((name != null) && name.toLowerCase() === 'viewport') {
        metalist[i].setAttribute('content', "width=" + width + ",user-scalable=yes,initial-scale=" + (window.screen.width / width) + ",target-densitydpi=device-dpi");
        return;
      }
    }
  };
  $.fn.autoLink = function(cls) {
    return $(this).each(function() {
      return $(this).html($(this).html().replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, '<a href="$1" class="' + cls + '" target="_blank">$1</a>'));
    });
  };
  $.contact = function() {
    var check, send;
    check = function(e) {
      var radio;
      e.preventDefault();
      if ($('#send').hasClass('off')) {
        return false;
      }
      radio = $('#contact [name=which]:checked').val();
      if (radio === 'question' || radio === 'feedback') {
        return send();
      } else {
        return alert("「ご質問」か「ご意見」どちらかをお選びください");
      }
    };
    send = function() {
      var _comment, _type, _which;
      $('#contact #before, #send').hide();
      $('#contact #after').show();
      _type = $('#contact').data('type');
      _which = $('#contact [name=which]:checked').val();
      _comment = $('#contact #comment').val();
      return _gaq.push(['t1._trackEvent', _type, _which, _comment]);
    };
    $('#send').on('click', check);
    return $('#comment').on('change keyup', function(e) {
      if ($(this).val() === "") {
        return $('#send').addClass('off');
      } else {
        return $('#send').removeClass('off');
      }
    });
  };

  /* Toggle Menu */
  $.fn.toggleMenu = function(cls) {
    return $(this).on('click', function(e) {
      e.preventDefault();
      return $('body').toggleClass(cls);
    });
  };

  /* Smooth Scroll */
  $.fn.smoothScroll = function(duration, easing) {
    duration || (duration = 400);
    easing || (easing = 'swing');
    return this.each(function(i) {
      var $target, hash;
      $target = $(this.hash);
      hash = this.hash;
      if ($target.length === 0 && hash !== '') {
        return this;
      }
      return $(this).on('click', function(e) {
        var clientHeight, dif, offsetHeight, offsetTop, position, speed;
        e.preventDefault();
        speed = duration;
        offsetHeight = document.body.offsetHeight;
        clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        offsetTop = hash === '' ? 0 : $target.offset().top;
        position = Math.floor(offsetTop);
        dif = offsetHeight - position;
        if (dif < clientHeight) {
          positoin -= clientHeight - dif;
        }
        $('html,body').delay(50).stop().animate({
          scrollTop: position
        }, speed, easing).queue(function() {
          return $(this).dequeue();
        });
        return false;
      });
    });
  };
  $.fn.arrangeRowsHeight = function(col) {
    var $elm, h, i, j, li, results;
    if (col <= 1) {
      return $(this).height('auto');
    }
    li = Math.ceil($(this).length / col);
    i = 0;
    results = [];
    while (i < li) {
      j = h = 0;
      while (j < col) {
        if (i * col + j > $(this).length) {
          break;
        }
        $elm = $($(this)[i * col + j]);
        $elm.height('auto');
        if ($elm.height() > h) {
          h = $elm.height();
        }
        j++;
      }
      j = 0;
      while (j < col) {
        if (i * col + j > $(this).length) {
          break;
        }
        $($(this)[i * col + j]).innerHeight(h);
        j++;
      }
      results.push(i++);
    }
    return results;
  };
  $.fn.cover = function(o) {
    var deploy, onLoad, onResize, p, resize;
    p = {
      'all': false
    };
    if (o) {
      p = $.extend(p, o);
    }
    this.$imgs = [];
    this.funcResize = '';
    onLoad = function(e) {
      $(this).off('load', onLoad);
      return deploy($(this));
    };
    deploy = (function(_this) {
      return function($elm) {
        $elm.css('position', 'absolute');
        _this.$imgs.push($elm);
        resize($elm);
        if (!_this.funcResize) {
          $(window).on('resize', onResize);
          return _this.funcResize = onResize;
        }
      };
    })(this);
    onResize = (function(_this) {
      return function(e) {
        var $img, k, len, ref, results;
        ref = _this.$imgs;
        results = [];
        for (k = 0, len = ref.length; k < len; k++) {
          $img = ref[k];
          results.push(resize($img));
        }
        return results;
      };
    })(this);
    resize = function($img) {
      var h, ph, pw, w;
      w = parseInt($img.attr('width'));
      h = parseInt($img.attr('height'));
      pw = p.all ? $(window).width() : $img.parent().width();
      ph = p.all ? $(window).height() : $img.parent().height();
      if (pw / w >= ph / h) {
        return $img.width(pw).height(pw / w * h).css('top', (ph - $img.height()) * 0.5).css('left', 0);
      } else {
        return $img.height(ph).width(ph / h * w).css('left', (pw - $img.width()) * 0.5).css('top', 0);
      }
    };
    $(this).each(function() {
      if ($(this).width() <= 0) {
        return $(this).on('load', onLoad);
      } else {
        return deploy($(this));
      }
    });
    return $(this);
  };
  $.detect = {
    isTouch: function() {
      return window['ontouchstart'] === null || ((window.DocumentTouch != null) && document instanceof (typeof DocumentTouch !== "undefined" && DocumentTouch !== null));
    },
    isModern: function() {
      var match;
      match = navigator.userAgent.match(/(MSIE\ )([0-9]+)/);
      if (match != null) {
        return parseInt(match[2]) > 8;
      }
      return true;
    },
    isSP: function() {
      var re;
      re = new RegExp(['Android', 'iPhone', 'iPod', 'Windows Phone OS'].join('|'));
      return navigator.userAgent.match(re) !== null;
    },
    isFF: function() {
      return navigator.userAgent.indexOf('Firefox') >= 0;
    }
  };
  $.attachUA = function() {
    var match;
    $('html').addClass(($.detect.isTouch() ? 'is-touch' : 'isnt-touch'));
    match = navigator.userAgent.match(/(MSIE|rv:?)\s?([0-9]+)/);
    if (match != null) {
      return $('html').addClass("ie" + match[2]);
    }
  };
  $.utils = {
    digit: function(_num, _digit) {
      var str;
      str = String(Math.floor(_num));
      while (str.length < _digit) {
        str = '0' + str;
      }
      return str;
    },
    comma: function(_num, _digit) {
      var i, k, l, ref, str, tempStr;
      tempStr = String(_num);
      str = "";
      l = String(_num).length;
      for (i = k = 0, ref = Math.floor(l / _digit); 0 <= ref ? k < ref : k > ref; i = 0 <= ref ? ++k : --k) {
        str = tempStr.substr(tempStr.length - _digit, _digit) + str;
        str = "," + str;
        tempStr = tempStr.substr(0, tempStr.length - _digit);
      }
      if (tempStr.length > 0) {
        return str = tempStr + str;
      }
    }
  };

  /*
  	$elm.x();
  	$param {number || string || undefined} _x
   */
  $.fn.extend({
    x: function(_x) {
      if (_x != null) {
        return $(this).css('left', _x + 'px');
      } else {
        return parseInt($(this).css('left'));
      }
    },
    y: function(_y) {
      if (_y != null) {
        return $(this).css('top', _y + 'px');
      } else {
        return parseInt($(this).css('top'));
      }
    },
    alpha: function(_alpha) {
      if (_alpha != null) {
        return $(this).css('opacity', _alpha);
      } else {
        return parseInt($(this).css('opacity'));
      }
    },
    scale: function(val) {
      if ((typeof Modernizr !== "undefined" && Modernizr !== null) && (Modernizr.csstransforms3d != null) && Modernizr.csstransforms3d) {
        return $(this).css({
          'transform': 'scale3d(' + val + ',' + val + ',1.0)',
          '-webkit-transform': 'scale3d(' + val + ',' + val + ',1.0)'
        });
      } else {
        return $(this).css({
          'transform': 'scale(' + val + ',' + val + ')',
          '-webkit-transform': 'scale(' + val + ',' + val + ')',
          '-ms-transform': 'scale(' + val + ',' + val + ')',
          '-o-transform': 'scale(' + val + ',' + val + ')'
        });
      }
    },
    radius: function(val) {
      return $(this).css('border-radius', val + 'px');
    },
    blur: function(_b) {
      _b = 'blur(' + _b + 'px)';
      return $(this).css({
        'filter': _b,
        '-webkit-filter': _b
      });
    }
  });
  $.extend(jQuery.easing, {
    easeInCubic: function(x, t, b, c, d) {
      return c * (t /= d) * t * t + b;
    },
    easeOutCubic: function(x, t, b, c, d) {
      return c * ((t = t / d - 1) * t * t + 1) + b;
    },
    easeInOutCubic: function(x, t, b, c, d) {
      if ((t /= d / 2) < 1) {
        return c / 2 * t * t * t + b;
      }
      return c / 2 * ((t -= 2) * t * t + 2) + b;
    },
    easeInOutSine: function(x, t, b, c, d) {
      return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
    },
    easeInOutExpo: function(x, t, b, c, d) {
      if (t === 0) {
        return b;
      }
      if (t === d) {
        return b + c;
      }
      if ((t /= d / 2) < 1) {
        return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
      }
      return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
  });
  return this;
})(jQuery);
