"use strict";


$ ->
	# $.createViewport(490)
	$.attachUA()
	$('#menu-toggle').toggleMenu 'is-menu-on'
	$('a[href*="#"]').not('.no').smoothScroll(600)

	@


( ($) ->

	$.createViewport = (width)->
		metalist = document.getElementsByTagName 'meta'
		for i in [0...metalist.length]
			name = metalist[i].getAttribute 'name'
			if name? and name.toLowerCase() is 'viewport'
				metalist[i].setAttribute 'content', "width=#{width},user-scalable=yes,initial-scale=#{window.screen.width/width},target-densitydpi=device-dpi"
				return
	
	$.fn.autoLink = (cls)->
		$(@).each ->
			$(@).html $(@).html().replace /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, '<a href="$1" class="' + cls + '" target="_blank">$1</a>'

	$.contact = ()->

		check = (e)->
			e.preventDefault()
			return false if $('#send').hasClass('off')

			radio = $('#contact [name=which]:checked').val()
			if radio is 'question' or radio is 'feedback'
				send()
			else
				alert "「ご質問」か「ご意見」どちらかをお選びください"

		send = ()->
			$('#contact #before, #send').hide()
			$('#contact #after').show()

			_type = $('#contact').data('type')
			_which = $('#contact [name=which]:checked').val()
			_comment = $('#contact #comment').val()
			_gaq.push ['t1._trackEvent', _type, _which, _comment]

		$('#send').on 'click', check

		$('#comment').on 'change keyup', (e)->
			if $(@).val() is ""
				$('#send').addClass('off')
			else
				$('#send').removeClass('off')


# function feedbackCheck() {
# 		var radio = $('#feedbackForm [name=solv]:checked').val();
# 		if (radio == 'yes') {
# 				feedbackAfter();
# 				return false;
# 		} else if  (radio == 'no') {
# 				feedbackAfter();
# 				return false;
# 		} else {
# 				alert('ボタンを選択してください。');
# 				return false;
# 		}
# }
# function feedbackAfter() {
# 		$('#feedback').hide();
# 		$('#feedbackAfter').show();
# 		var _question = $('#feedbackForm input:hidden[name=q]').val();
# 		var _solv = $('#feedbackForm [name=solv]:checked').val();
# 		var _comment = $('#feedbackForm textarea[name=comment]').val();
# 		if(_comment === null || _comment === '') {
# 				_comment = '(not set)';
# 		}
# 		_gaq.push(['t1._trackEvent', _question, _solv, _comment]);
# }


	### Toggle Menu ###
	$.fn.toggleMenu = (cls)->
		$(@).on 'click', (e)->
			e.preventDefault()
			$('body').toggleClass cls

	### Smooth Scroll ###
	$.fn.smoothScroll = (duration, easing)->
		duration ||= 400
		easing ||= 'swing'
		@.each (i)->
			$target = $(@.hash)
			hash = @.hash
			if $target.length == 0 and hash isnt ''
				return @

			$(@).on 'click', (e)->
				e.preventDefault()

				speed = duration
				offsetHeight = document.body.offsetHeight
				clientHeight = document.documentElement.clientHeight || document.body.clientHeight
				offsetTop = if hash is '' then 0 else $target.offset().top
				position = Math.floor(offsetTop)
				dif = offsetHeight - position
				positoin -= clientHeight - dif if( dif < clientHeight)

				$('html,body').delay(50).stop()
					.animate {scrollTop:position}, speed, easing
					.queue ()-> $(@).dequeue()

				false


	$.fn.arrangeRowsHeight = (col) ->
		return $(@).height('auto') if col <= 1
		li = Math.ceil($(@).length/col)
		i = 0
		while i < li
			j = h = 0
			while j < col
				break if (i*col+j > $(@).length)
				$elm = $( $(@)[i*col+j] )
				$elm.height 'auto'
				h = $elm.height() if ($elm.height() > h)
				j++
			j = 0
			while j < col
				break if i*col+j > $(@).length
				$( $(@)[i*col+j] ).innerHeight h
				j++
			i++

	# $.fn.arrangeRowsHeight = (col, responsive) ->
	# 	$(window).on 'load.arh resize.arh', (e)=>
	# 		_col = 0
	# 		_w = $(window).width()
	# 		if responsive?
	# 			for i in [0...responsive.length]
	# 				if i+1 < responsive.length
	# 					if responsive[i+1][0] <= _w < responsive[i][0]
	# 						_col = responsive[i][1]
	# 						break
	# 				else
	# 					if _w < responsive[i][0]
	# 						_col = responsive[i][1]
	# 						break
	# 			_col = col if _col is 0
	# 		else
	# 			_col = col
	# 		return $(@).height('auto') if _col <= 1 or _col is ""

	# 		li = Math.ceil $(@).length/_col
	# 		i = 0
	# 		while i < li
	# 			j = h = 0
	# 			while j < _col
	# 				break if (i*_col+j > $(@).length)
	# 				$elm = $( $(@)[i*_col+j] )
	# 				$elm.height 'auto'
	# 				h = $elm.innerHeight() if $elm.innerHeight() > h
	# 				j++
	# 			j = 0
	# 			while j < _col
	# 				break if i*_col+j > $(@).length
	# 				$( $(@)[i*_col+j] ).innerHeight h
	# 				j++
	# 			i++
	# 	@

	$.fn.cover = (o) ->
		p = {'all':false}
		p = $.extend(p, o) if o

		@$imgs = [];
		@funcResize = ''

		onLoad = (e) ->
			$(@).off 'load', onLoad
			deploy $(@)
		deploy = ($elm) =>
			$elm.css 'position', 'absolute'
			@$imgs.push $elm
			resize $elm
			if !@funcResize
				$(window).on 'resize', onResize
				@funcResize = onResize
		onResize = (e) =>
			for $img in @$imgs
				resize $img
		resize = ($img) ->
			w = parseInt $img.attr 'width'
			h = parseInt $img.attr 'height'
			pw = if p.all then $(window).width() else $img.parent().width()
			ph = if p.all then $(window).height() else $img.parent().height()
			if pw/w >= ph/h
				$img
					.width pw
					.height pw/w*h
					.css 'top', (ph-$img.height())*0.5
					.css 'left', 0
			else
				$img
					.height ph
					.width ph/h*w
					.css 'left', (pw-$img.width())*0.5
					.css 'top', 0


		$(@).each ->
			if $(@).width() <= 0
				$(@).on 'load', onLoad
			else
				deploy $(@)

		$(@)

	$.detect =
		isTouch: () ->
			window['ontouchstart'] is null || (window.DocumentTouch? and document instanceof DocumentTouch?)
			
		isModern: () ->
			match = navigator.userAgent.match(/(MSIE\ )([0-9]+)/)
			if match?
				return parseInt(match[2]) > 8
			return true
		isSP: () ->
			re = new RegExp( ['Android', 'iPhone', 'iPod', 'Windows Phone OS'].join('|') )
			(navigator.userAgent.match(re) isnt null)

		isFF: ->
			(navigator.userAgent.indexOf('Firefox') >= 0)

	$.attachUA = ()->
		$('html').addClass (if $.detect.isTouch() then 'is-touch' else 'isnt-touch')
		match = navigator.userAgent.match(/(MSIE|rv:?)\s?([0-9]+)/)
		if match?
			$('html').addClass( "ie#{match[2]}")


	$.utils =
		digit: (_num, _digit) ->
			str = String( Math.floor(_num) )
			while (str.length < _digit)
				str = '0' + str;
			str

		comma: ( _num, _digit ) ->
			tempStr = String(_num)
			str = ""
			l = String(_num).length
			for i in [0...Math.floor(l/_digit)]
				str = tempStr.substr( tempStr.length - _digit, _digit) + str
				str = "," + str
				tempStr = tempStr.substr( 0, tempStr.length - _digit );
			str = tempStr + str if tempStr.length > 0


	###
	$elm.x();
	$param {number || string || undefined} _x
	###
	$.fn.extend {
		x: (_x) ->
			if _x?
				$(@).css 'left', _x+'px'
			else
				parseInt( $(@).css 'left' )
		y: (_y) ->
			if _y?
				$(@).css 'top', _y + 'px'
			else
				parseInt( $(@).css 'top')
		alpha: (_alpha) ->
			if _alpha?
				$(@).css 'opacity', _alpha
			else
				parseInt( $(@).css 'opacity' )
		scale: (val) ->
			if Modernizr? and Modernizr.csstransforms3d? and Modernizr.csstransforms3d
				$(@).css {
					'transform': 'scale3d(' + val + ',' + val + ',1.0)'
					'-webkit-transform': 'scale3d(' + val + ',' + val + ',1.0)'
				}
			else
				$(@).css {
					'transform': 'scale(' + val + ',' + val + ')'
					'-webkit-transform': 'scale(' + val + ',' + val + ')'
					'-ms-transform': 'scale(' + val + ',' + val + ')'
					'-o-transform': 'scale(' + val + ',' + val + ')'
				}
		radius: (val) ->
			$(@).css 'border-radius', val + 'px'
		blur: (_b) ->
			# if _b? and  Modernizr? and Modernizr.cssfilters? and Modernizr.cssfilters
			_b = 'blur(' + _b + 'px)'
			$(@).css {
				'filter': _b
				'-webkit-filter': _b
			}
			# else
			# 	$(@)

	}

	$.extend jQuery.easing, {
		easeInCubic: (x, t, b, c, d) ->
			return c*(t/=d)*t*t + b
		easeOutCubic: (x, t, b, c, d) ->
				return c*((t=t/d-1)*t*t + 1) + b
		easeInOutCubic: (x,t,b,c,d) ->
			return c/2*t*t*t + b if ((t/=d/2) < 1)
			c/2*((t-=2)*t*t + 2) + b
		easeInOutSine: (x,t,b,c,d) ->
			-c/2 * (Math.cos(Math.PI*t/d) - 1) + b
		easeInOutExpo: (x,t,b,c,d) ->
			return b if t is 0
			return b+c if t is d
			return ( c/2 * Math.pow(2, 10 * (t - 1)) + b ) if ((t/=d/2) < 1)
			c/2 * (-Math.pow(2, -10 * --t) + 2) + b
	}

	@
)(jQuery)


